<template>
  <div>
    <div class="plugin-dialog" v-if="dialogSecretShow">
      <div class="dialog-one-box" v-if="dialogSecretShowOne">
        <div class="btns" @click="handleSecret">填写授权码</div>
        <div class="tips">* 激活PRO版本即可使用</div>
      </div>
      <div class="two-box-father" v-if="dialogSecretShowTwo">
        <div class="dialog-two-box">
          <div class="box-left">
            <p class="tips1">联系我们获取授权码</p>
            <img class="qr-code" src="~@/assets/images/er-code.png">
            <p class="tips2">扫一扫，微信联系我们</p>
            <div class="phone-box">
              <img src="~@/assets/images/phone1.png">
              <div class="phone-right">
                <p class="tips3">电话咨询</p>
                <p class="tips4">400-800-8888</p>
              </div>
            </div>
          </div>
          <div class="box-right">
            <div class="right-tips">激活PRO版本</div>
            <el-input class="right-input" placeholder="请输入激活码" v-model="cloud_secret" maxlength="255"></el-input>
            <div class="right-btn">
              <el-button @click="closeSecret">取消</el-button>
              <el-button type="primary" @click="submitSecret" :loading="dialogSecretLoading">提交验证</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="ditchbox">
      <div class="ditchboxcon">
        <div class="ditchitem" v-for="(item, index) in ditchList" :key="index">
          <div class="ditchboxup">
            <img :src="item.pic_url" alt="" />
            <p>{{ item.name }}</p>
          </div>
          <div class="ditchconter">
            <h5>{{ item.display_name }}</h5>
            <div class="diturl" v-if="item.status == 'installed' && item.pc_url">
              <p>{{ item.pc_url }}</p>
              <div class="ditlast">
                <a href="javascript:void(0);" @click="copy(item.pc_url)">复制</a>
                <a :href="item.pc_url" target="_blank">预览</a>
              </div>
            </div>
            <p class="ditext">
              <!-- 最后修改时间： <span>2022.01.01 14:00:00</span> -->
            </p>
            <p class="ditext ditprice" v-if="item.status!='installed'&&item.status!='installed'!='uninstalled'&&!item.new_version">
              价格: <span>￥{{ item.price }}</span>
            </p>
          </div>
          <div class="ditlasted">
            <template v-if="item.new_version">
              <a href="javascript:void(0);" @click="updateVersion(item)" class="install_button">升级新版本({{ item.new_version
              }})</a>
            </template>
            <template v-if="item.status == 'installed'">
              <router-link :to="item.route" class="install_button">{{ item.name }}管理</router-link>
            </template>
            <template v-else>
              <a href="javascript:void(0);" class="install_button" v-if="item.status == 'not_order'"
                @click="buyShop(item)">立即购买</a>
              <template v-else-if="item.status == 'not_pay'">
                <a href="javascript:void(0);" class="install_button" @click="payNow(item)">付款</a>
              </template>
              <template v-else-if="item.status == 'uninstalled'">
                <el-button class="install_button package_btn" size="small" type="primary" @click="install(item)"
                  :loading="installLoading">安装</el-button>
              </template>
            </template>
          </div>
          <div class="plugin-installed" v-if="item.status == 'installed'">
            <img src="../../assets/images/plugin-installed.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="付款" :visible.sync="payDialogVisible">
      <div>
        <table v-if="ditchItem.order" class="order-table">
          <colgroup>
            <col style="width: 80px" />
            <col style="width: auto" />
          </colgroup>
          <tr>
            <td>订单编号</td>
            <td style="font-weight: bold">{{ ditchItem.order.order_no }}</td>
          </tr>
          <tr>
            <td>订单金额</td>
            <td style="text-decoration: line-through; color: #909399">
              ￥{{ ditchItem.order.price }}
            </td>
          </tr>
          <tr>
            <td>支付金额</td>
            <td style="color: #ff4544; font-weight: bold">
              ￥{{ ditchItem.order.pay_price }}
            </td>
          </tr>
          <tr>
            <td>支付状态</td>
            <td>
              <template v-if="ditchItem.order.is_pay == 1">
                <span style="color: #67c23a">已支付</span>
              </template>
              <template v-else>
                <span style="color: #909399">未支付</span>
              </template>
            </td>
          </tr>
          <tr>
            <td>付款方式</td>
            <td>请联系管理员完成付款。</td>
          </tr>
        </table>
        <div v-else>暂无订单信息</div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="payDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ditchList: [],
      itenName: "",
      ditchItem: "",
      payDialogVisible: false,
      installLoading: false,
      cloud_secret: '',
      dialogSecretShow: false, // 遮罩层
      dialogSecretShowOne: false, // 一级弹窗
      dialogSecretShowTwo: false, // 二级弹窗
      dialogSecretLoading: false // 保存loading
    };
  },
  async created() {
    // 获取是否已经填写过激活码

    let resource = await this.$get(this.$apis.resource, { keys: ["cloud.installed"] })
    console.log(resource, 'resource');
    if (resource.code == 200) {
      if (!resource.data['cloud.installed']) {
        this.dialogSecretShow = true
        this.dialogSecretShowOne = true
      }
    }

    this.getchannel();
  },
  methods: {
    copy(data) {
      let that = this;
      //   let url = data;
      //   let oInput = document.createElement("input");
      //   oInput.value = url;
      //   document.body.appendChild(oInput);
      //   oInput.select(); // 选择对象;
      //   document.execCommand("Copy"); // 执行浏览器复制命令
      //   this.$message({
      //     message: "复制成功",
      //     type: "success",
      //   });
      //   oInput.remove();
      navigator.clipboard
        .writeText(data)
        .then(() => {
          that.$message.success("复制成功");
        })
        .catch((err) => {
          console.log(err);
          that.$message.error("复制失败");
        });
    },
    handlePC() {
      this.$router.push({ path: "/pc/setting" });
    },
    install(item) {
      this.itenName = item.name;
      this.$confirm("确认安装插件？", "提示")
        .then((e) => {
          this.doInstall();
        })
        .catch((e) => { });
    },
    doInstall() {
      this.installLoading = true;
      let stopContent = "插件安装中，请勿关闭或刷新页面！";
      window.onbeforeunload = function(event) {
        event.returnValue = stopContent;
      };
      if (parent && parent.stopPageClose) {
        parent.stopPageClose(true, stopContent);
      }

      this.$post(this.$apis.appPluginInstall + this.itenName).then((res) => {
        console.log("appPluginInstall", res);
        window.onbeforeunload = null;
        if (parent && parent.stopPageClose) {
          parent.stopPageClose(false);
        }
        if (res.code == 200) {
          this.$message.success(res.message);
          location.reload();
        } else {
          this.installLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    getchannel() {
      let that = this;
      this.$get(this.$apis.getChannelList)
        .then((res) => {
          if (res.code == 200) {
            that.ditchList = res.data;
          } else {
            that.$message({
              type: "warning",
              message: "" + res.message,
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          that.common.message(that, err.message);
        });
    },
    buyShop(item) {
      this.itenName = item.name;
      let that = this;
      this.$confirm("确认购买该插件？", "提示")
        .then((e) => {
          this.createOrder();
        })
        .catch((e) => { });
    },
    createOrder() {
      this.buyLoading = true;
      this.$post(this.$apis.appPluginBuy + this.itenName).then((res) => {
        console.log("appPluginBuy", res);
        if (res.code == 200) {
          this.$message.success("订单提交成功");
          location.reload();
        } else {
          this.buyLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    payNow(item) {
      this.ditchItem = item;
      this.payDialogVisible = !this.payDialogVisible;
    },
    updateVersion(item) {
      this.itenName = item.name;
      this.$confirm("确认升级插件？", "提示")
        .then((e) => {
          this.doUpdate();
        })
        .catch((e) => { });
    },
    doUpdate() {
      this.uploadLoading = true;
      let stopContent = "插件升级中，请勿关闭或刷新页面！";
      window.onbeforeunload = function(event) {
        event.returnValue = stopContent;
      };
      if (parent && parent.stopPageClose) {
        parent.stopPageClose(true, stopContent);
      }

      this.$post(this.$apis.appPluginUpdate + this.itenName).then((res) => {
        console.log("appPluginUpdate", res);
        window.onbeforeunload = null;
        if (parent && parent.stopPageClose) {
          parent.stopPageClose(false);
        }
        if (res.code == 200) {
          this.$message.success(res.message);
          location.reload();
        } else {
          this.uploadLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    handleSecret() {
      this.dialogSecretShowOne = false
      this.dialogSecretShowTwo = true
    },
    closeSecret() {
      this.dialogSecretShowTwo = false
      this.dialogSecretShowOne = true
      this.cloud_secret = ''
    },
    submitSecret() {
      if (!this.cloud_secret) {
        this.$message.error('激活码不能为空');
        return false
      }
      this.dialogSecretLoading = true
      this.$post(this.$apis.appPluginSecret, { cloud_secret: this.cloud_secret }).then(res => {
        if (res.code == 200) {
          // 如果成功，关闭弹窗 遮罩层
          if (res.data.result) {
            this.$message.success(res.message)
            this.dialogSecretShowTwo = false
            this.dialogSecretShow = false
          }
        } else {
          this.$message.error(res.message)
        }
        this.dialogSecretLoading = false
      }).catch(error => {
        this.$message.error(error.message)
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.plugin-dialog {
  position: fixed;
  z-index: 9;
  background-color: rgba($color: #000000, $alpha: .6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;

  .dialog-one-box {
    width: calc(100% - 239px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btns {
      width: 116px;
      height: 40px;
      border-radius: 4px;
      background: linear-gradient(90deg, #030F9F 0%, #3444FF 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .tips {
      color: #FFFFFF;
      font-size: 16px;
      margin-top: 16px;
    }
  }

  .two-box-father {
    width: calc(100% - 239px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dialog-two-box {
    width: 672px;
    height: 363px;
    display: flex;
    border-radius: 3px;
    background-color: #fff;

    .box-left {
      width: 268px;
      height: 100%;
      background-color: #1E2CD4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .tips1 {
        color: rgba($color: #FFF, $alpha: .9);
        margin-top: 28px;
        font-size: 16px;
      }

      .qr-code {
        width: 120px;
        height: 120px;
        margin-top: 38px;
      }

      .tips2 {
        color: rgba($color: #FFF, $alpha: .9);
        margin-top: 10px;
        font-size: 14px;
      }

      .phone-box {
        display: flex;
        align-items: center;
        margin-top: 42px;

        >img {
          width: 33px;
          height: 33px;
          margin-right: 8px;
        }

        .phone-right {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;

          .tips3 {
            color: rgba($color: #FFF, $alpha: .9);
            font-size: 13px;
          }

          .tips4 {
            color: rgba($color: #FFF, $alpha: .9);
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

    .box-right {
      position: relative;
      width: 404px;
      height: 100%;
      box-sizing: border-box;
      padding: 28px 32px;

      .right-tips {
        color: rgba($color: #000000, $alpha: .9);
        font-weight: 600;
        font-size: 16px;
      }

      .right-input {
        margin-top: 50px;
      }

      .right-btn {
        position: absolute;
        right: 32px;
        bottom: 28px;
      }
    }
  }
}

.ditchbox {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
}

.order-table {
  margin: 10px 0;
}

.order-table td {
  padding: 5px 0;
}

.order-table tr td:first-child {
  text-align: right;
  padding-right: 15px;
  width: 106px;
}

.ditchboxcon {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 380px);
}

.ditchitem {
  width: 100%;
  height: 320px;
  background: #f6f8ff;
  // border-radius: 16px;
  border-radius: 6px;
  padding-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border: 1px solid #edebf1;
  box-sizing: border-box;
  transition: all 0.3s;
}

.ditchitem:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.18);
}

.plugin-installed {
  position: absolute;
  top: 0;
  right: 0;
  // width: 16px;
  // height: 16px;
  width: 50px;
  height: 50px;
}

.plugin-installed img {
  display: block;
  width: 100%;
  height: 100%;
}

.ditchboxup {
  align-items: center;
  height: 70px;
  padding: 18px 24px;
  color: #292931;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  box-sizing: border-box;
}

.ditchboxup img {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  margin-right: 10px;
  display: block;
}

.ditchboxup p {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}

.ditchconter {
  width: 100%;
  padding: 17px 20px 0 20px;
  box-sizing: border-box;
  height: 180px;
}

.ditchconter h5 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.diturl {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.diturl p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 10px;
  max-width: 76%;
  min-width: 76%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ditlast a {
  display: inline-block;
  font-size: 14px;
  color: var(--fontColor, #fb6638);
  position: relative;
  margin-right: 10px;
}

.ditlast a:last-child {
  margin-right: 0;
}

.ditlast a::before {
  content: "";
  width: 1px;
  height: 12px;
  background: var(--fontColor, #fb6638);
  position: absolute;
  top: 4px;
  right: -7px;
}

.ditlast a:last-child::before {
  display: none;
}

.ditext {
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
}

.ditprice {
  span {
    color: #ff3333;
  }
}

.ditlasted {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ditlasted .install_button {
  display: block;
  // width: 100%;
  flex: 1;
  height: 46px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 46px;
  border-radius: 4px;
  background: var(--fontColor, #fb6638);
}

.ditlasted .install_button:nth-child(n+2) {
  margin-left: 10px;
}

.package_btn {
  padding: 0 !important;
}
</style>